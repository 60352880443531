import React, { useContext } from 'react'
import { CChart, CChartBar } from '@coreui/react-chartjs'
import { useState, useEffect, createRef } from 'react'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { contextAPI } from './Context';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import ReactDOMServer from 'react-dom/server';
import Yoga from './Recommendations/Yoga';
import Diet from './Recommendations/Diet';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import { NavLink, Outlet } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportIcon from '@mui/icons-material/Report';
import PersonIcon from '@mui/icons-material/Person';
import loader from './Animations/loadingaiwell.json'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ListItemIcon from '@mui/material/ListItemIcon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import RestoreIcon from '@mui/icons-material/Restore';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react'
import Bot from './Animations/newbot.json'
import Package from './Animations/packages.json'
import axios from 'axios';
import { useSelector } from 'react-redux';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { es } from 'date-fns/locale';
import { setAuthToken } from './Auth/setAuthToken';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Interests } from '@mui/icons-material';

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
}

function Final(props) {
    const ref = createRef(null)
    const { window } = props;
    const [loading, setloading] = useState(false)
    // const [generatedPDF, setGeneratedPDF] = useState(null);

    let { setPrakritiresult, setvikritiresult, setinterest, setDisease, vikritiresult, prakritiresult, interests, products, disease, setvikriti, prakriti_data, setPrakriti, vikriti_data } = useContext(contextAPI)

    let navigate = useNavigate()

    let [PDF, setpdf] = useState(null)

    let data = JSON.parse(localStorage.getItem('Userdetails'))

    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerWidth = 250;
    const [value, setValue] = useState(0);
    const Profile = useSelector((state) => state.Profile.User_Profile)
    const [count, setcount] = useState(0)

    const handleChange = (event, newValue) => {
        if (event.type !== 'click' || (event.type === 'click' && samePageLinkNavigation(event))) {
            setValue(newValue);

        }
    };

    const datas = [
        { value: 5, label: 'A' },
        { value: 10, label: 'B' },
        { value: 15, label: 'C' },
        { value: 20, label: 'D' },
    ];

    const size = {
        width: 350,
        height: 300,
    };

    const [state, setState] = useState({
        left: false,
    });

    useEffect(() => {
        if (!localStorage.getItem('Userdetails')) {
            navigate('/Signin')
        }
        else {
            getPrakriti()
            getvikriti()
        }
    }, [count])


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    var container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <Box
            role="presentation"
        >
            <List className='sidebar'>
                <div style={{ width: "240", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <img src='aiwelllogoa512x512.png' id='aiwell-logo'></img>
                    <IconButton onClick={handleDrawerToggle}><ArrowBackIcon /></IconButton>
                </div>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            Home
                        </p>
                    </ListItemButton>

                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate('/Profile_page') }}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            Profile
                        </p>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate('/Reports') }} >
                        <ListItemIcon>
                            <InsertDriveFileIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            Reports
                        </p>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <p id='pharm' className='mb-0'>E-Pharm</p>
            <List>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate('/Shop') }}>
                        <ListItemIcon>
                            <ShoppingBagIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            Shop
                        </p>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate('/Orders') }}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            My Orders
                        </p>
                    </ListItemButton>
                </ListItem>

            </List>
            <Divider />
            <p id='tele' className='mb-0'>Teleconsultation</p>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate("/Tele") }}>
                        <ListItemIcon>
                            <PersonSearchIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0'>
                            Find Doctor
                        </p>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { navigate("/Appointment_page") }}>
                        <ListItemIcon>
                            <PermContactCalendarIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0' >
                            My Appointments
                        </p>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <FavoriteIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0' >
                            My Doctors
                        </p>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <p id='Settings' className='mb-0'>General</p>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <p id='listitemtext' className='mb-0' onClick={() => { navigate("/Settings") }}>
                            Settings
                        </p>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>

    );

    let getPrakriti = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/getPrakriti')
            if (res.status === 200) {
                setPrakriti(res?.data?.Data)
                setPrakritiresult(res?.data?.Data?.result)

                setTimeout(() => {
                    setloading(true)
                }, 1000)

            }
        }
        catch (error) {
            if (error?.response?.data === 'No Document') {
                setTimeout(() => {
                    setloading(true)
                }, 1000)

            }
            if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.status === 500) {
                renew()
            }
            else {
                navigate('/Signin')
            }

        }

    }




    const isDesktop = useMediaQuery('(min-width: 1024px)');

    function calculateVikriti(vatfinal, pitfinal, kapfinal) {
        let stringJoiner = new Array();
        let unsortMap = new Map();

        function addToMap(condition, key, value, message) {
            if (condition) {
                if (stringJoiner.length >= 1) {
                    if (value > 10) {
                        unsortMap.set(key, value);
                    }
                } else {
                    unsortMap.set(key, value);
                    console.log(message.toLowerCase());
                }
            }
        }

        addToMap(vatfinal > 0, "V", vatfinal, "V");
        addToMap(pitfinal > 0, "P", pitfinal, "P");
        addToMap(kapfinal > 0, "K", kapfinal, "K");

        let sortedMapAsc = new Map([...unsortMap.entries()].sort((a, b) => b[1] - a[1]));
        sortedMapAsc.forEach((value, key) => {
            stringJoiner.push(key);
        });

        return stringJoiner.join('');
    }



    let getvikriti = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)

            let res = await axios.post('https://api.ayurai.in/api/data/getVikriti')

            if (res.status === 200 && res.data && res.data.Data) {
                setvikriti(res.data.Data)
                let vikritiresult = res?.data?.Data.VIKRITIVATAPULSE == 0 && res?.data?.Data?.VIKRITIPITTAPULSE == 0 && res?.data?.Data?.VIKRITIKAPHAPULSE == 0 ?
                    [((res?.data?.Data?.vatascore / res.data.Data?.count) * 100 * 0.7), ((res?.data?.Data?.pittascore / res?.data?.Data?.count) * 100 * 0.7),

                    ((res?.data?.Data?.kaphascore / res?.data?.Data?.count) * 100 * 0.7)] : res?.data?.Data?.count === 0 ?

                        [((
                            res?.data?.Data?.
                                VIKRITIVATAPULSE) / (res?.data?.Data?.
                                    VIKRITIVATAPULSE + res?.data?.Data?.
                                        VIKRITIPITTAPULSE + res?.data?.Data?.
                                        VIKRITIKAPHAPULSE) * 100 * 0.3), ((
                                            res?.data?.Data?.
                                                VIKRITIPITTAPULSE) / (res?.data?.Data?.
                                                    VIKRITIVATAPULSE + res?.data?.Data?.
                                                        VIKRITIPITTAPULSE + res?.data?.Data?.
                                                        VIKRITIKAPHAPULSE) * 100 * 0.3),

                        ((res?.data?.Data?.
                            VIKRITIKAPHAPULSE) / (res?.data?.Data?.
                                VIKRITIVATAPULSE + res?.data?.Data?.
                                    VIKRITIPITTAPULSE + res?.data?.Data?.
                                    VIKRITIKAPHAPULSE) * 100 * 0.3)]
                        :
                        [((res?.data?.Data?.vatascore / res?.data?.Data?.count) * 100 * 0.7) + ((
                            res?.data?.Data?.
                                VIKRITIVATAPULSE) / (res?.data?.Data?.
                                    VIKRITIVATAPULSE + res?.data?.Data?.
                                        VIKRITIPITTAPULSE + res?.data?.Data?.
                                        VIKRITIKAPHAPULSE) * 100 * 0.3), ((res?.data?.Data?.pittascore / res?.data?.Data?.count) * 100 * 0.7) + ((
                                            res?.data?.Data?.
                                                VIKRITIPITTAPULSE) / (res?.data?.Data?.
                                                    VIKRITIVATAPULSE + res?.data?.Data?.
                                                        VIKRITIPITTAPULSE + res?.data?.Data?.
                                                        VIKRITIKAPHAPULSE) * 100 * 0.3),

                        ((res?.data?.Data?.kaphascore / res?.data?.Data?.count) * 100 * 0.7) + ((
                            res?.data?.Data?.
                                VIKRITIKAPHAPULSE) / (res?.data?.Data?.
                                    VIKRITIVATAPULSE + res?.data?.Data?.
                                        VIKRITIPITTAPULSE + res?.data?.Data?.
                                        VIKRITIKAPHAPULSE) * 100 * 0.3)]

                const result = calculateVikriti(vikritiresult[0], vikritiresult[1], vikritiresult[2])
                setvikritiresult(result)
            } else {
                // Handle the case where the data is not in the expected format
                console.log('Unexpected response structure:', res);
            }
        } catch (error) {
            console.log('Error fetching vikriti:', error?.response?.data?.error?.message || error.message);

            if (error?.response?.data?.error?.message === 'jwt expired' || error?.response?.status === 500) {
                renew()
            } else {
                if (!error?.response?.status === 400) {
                    navigate('/Signin')
                }

            }
        }
    }



    let getUserInterest = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/getInterests')
            if (res.status === 200) {
                setinterest(res.data.Data)
            }
        }
        catch (error) {
            if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.status === 500) {
                renew()
            }
            else {
                navigate('/Signin')
            }
        }
    }

    let getUserDisease = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/getDisease')
            if (res?.status === 200) {
                setDisease(res?.data?.Data)
            }
        }
        catch (error) {
            if (error?.response?.data?.error?.message === 'jwt expired' || error?.response?.status === 500) {
                renew()
            }
            else {

                if (!error?.response?.status === 400) {
                    navigate('/Signin')
                }

            }
        }
    }



    useEffect(() => {
        getUserInterest()
        getUserDisease()
    }, [count])

    let results = {
        V: "VATA",
        P: "PITTA",
        K: "KAPHA",
        VP: "VATA PITTA",
        KP: "KAPHA PITTA",
        PV: "PITTA VATA",
        PK: "PITTA KAPHA",
        VPK: "VATA PITTA KAPHA",
        KPV: "KAPHA PITTA VATA",
        PKV: "PITTA KAPHA VATTA",
        PVK: "PITTA VATA KAPHA",
        KVP: "KAPHA VATA PITTA",
        VK: "VATA KAPHA",
        KV: "KAPHA VATA",
    }

    let renew = async () => {

        try {
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: data?.email,
                token: data?.refreshToken
            })
            if (res.status === 200) {

                let data = res.data

                localStorage.setItem('jwt-token', data.access_token)

                setcount(count + 1)

            }
        }
        catch (error) {
            if (error) {
                navigate('/Signin')
            }

        }
    }

    useEffect(() => {
        getPrakriti()
        getvikriti()

    }, [])


    const palette = ['rgb(100,181,246)', 'rgb(229,115,115)', 'rgb(129,199,132)'];

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: 100,
            },
        },
    };

    const valueFormatter = (value) => `${value}%`;



    return <>{
        loading ? <Box sx={{ display: 'flex' }}>
            <AppBar className='Navbar'
                style={{ backgroundColor: "white", display: "flex", flexDirection: "row" }}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}>

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon style={{ color: "blue" }} />
                </IconButton>
                <div></div>
                <div style={{ alignSelf: "flex-end" }}><Button onClick={() => { navigate('/Orders') }}><RestoreIcon /></Button  ><Button onClick={() => { navigate('/Cart') }}><ShoppingCartIcon /></Button></div>
            </AppBar>
            <div>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >   <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>


                </Box>
            </div>
            <Box
                component="main"
                sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <div className='question-page pt-5' ref={ref} style={{ height: '100%' }}>
                    <div>
                        {Profile &&
                            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }} className='mes'>
                                <Lottie animationData={Bot} loop={true} style={{ width: "5rem" }}></Lottie>
                                <p id="wlcometxt">Hi, {Profile.firstname} {Profile.lastname} Welcome</p>
                            </div>
                        }
                        <div className='app-bars'>
                            <div onClick={() => {
                                navigate('/Tele')
                            }}>
                                <img src='/asdfghjk.png'></img>
                                <p className='mb-0'>Find</p>
                                <p>Doctors</p>
                            </div>
                            <div onClick={() => {
                                navigate('/Shop')
                            }}>
                                <img src='Untitled design (1).png'></img>
                                <p className='mb-0'>Herbal</p>
                                <p>Supplements</p>
                            </div>
                            <div onClick={() => {
                                navigate('/lab_test')
                            }}>
                                <img src='/Untitled design (2).png'></img>
                                <p className='mb-0'>Lab</p>
                                <p>tests</p>

                            </div>
                        </div>
                        {prakriti_data.length != 0 ?
                            <div className='package-container' onClick={() => {
                                navigate('/Package')
                            }}>
                                <div>
                                    <p className='mb-0'>Your personalized wellness package is ready!</p>
                                    <p className='mb-0'>Click here to view</p>
                                </div>
                                <div>
                                    <Lottie animationData={Package} style={{ width: "5rem" }}></Lottie>
                                </div>
                            </div> : <></>
                        }

                        <div className='chart-page'>
                            <p delay={1e3} cascade damping={1e-1} id='chart-title' className='mb-0'>
                                Constitutional Dosha Balance Ratio </p>
                            <div className='charts'>

                                {vikritiresult != '' ? <div className='chart'>
                                    {vikriti_data.VIKRITIVATAPULSE == 0 && vikriti_data.VIKRITIPITTAPULSE == 0 && vikriti_data.VIKRITIKAPHAPULSE == 0 ?
                                        <BarChart
                                            width={!isDesktop ? 300 : 500}
                                            height={300}
                                            colors={palette}
                                            series={[{
                                                data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7 || 0), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7 || 0),

                                                ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7 || 0)],
                                                type: 'bar'
                                            }]}

                                            xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]}

                                        /> : vikriti_data?.count === 0
                                            ? <BarChart

                                                width={!isDesktop ? 250 : 500}
                                                height={300}
                                                colors={palette}
                                                series={[{
                                                    data: [((
                                                        vikriti_data?.
                                                            VIKRITIVATAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0), ((
                                                                        vikriti_data?.
                                                                            VIKRITIPITTAPULSE) / (vikriti_data?.
                                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0),

                                                    ((
                                                        vikriti_data?.
                                                            VIKRITIKAPHAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0)],
                                                    type: 'bar'
                                                }]}
                                                xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]}

                                            /> : <BarChart

                                                width={!isDesktop ? 250 : 500}
                                                height={300}
                                                colors={palette}
                                                series={[{
                                                    data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7) + ((
                                                        vikriti_data?.
                                                            VIKRITIVATAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7) + ((
                                                                        vikriti_data?.
                                                                            VIKRITIPITTAPULSE) / (vikriti_data?.
                                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0),

                                                    ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7) + ((
                                                        vikriti_data?.
                                                            VIKRITIKAPHAPULSE) / (vikriti_data?.
                                                                VIKRITIVATAPULSE + vikriti_data?.
                                                                    VIKRITIPITTAPULSE + vikriti_data?.
                                                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0)], type: 'bar'
                                                }]}

                                                xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]}

                                            // data={{
                                            //     labels: [`Vata`, 'Pitta', 'Kapha',],

                                            //     datasets: [
                                            //         {
                                            //             label: "",
                                            //             backgroundColor: ['rgb(100,181,246)', 'rgb(229,115,115)', 'rgb(129,199,132)'],




                                            //         },
                                            //     ],
                                            // }}




                                            />}

                                    {/* <BarChart
                                        colors={palette}
                                        series={[
                           
                                            {
                                                arcLabel: (item) => `${item} \n${Math.floor(item)}%`,
                                                // arcLabelMinAngle: 45,
                                                
                                                data: [Math.floor((vikriti_data.vatascore / vikriti_data.count) * 100), Math.floor((vikriti_data.pittascore / vikriti_data.count) * 100), Math.floor((vikriti_data.kaphascore / vikriti_data.count) * 100)],
                                                valueFormatter 
                                                // data: [{ value: prakriti_data.vata, name: 'vata' }, { value: prakriti_data.pitta, name: 'pitta' }, { value: prakriti_data.kapha, name: 'kapha' }]
                                            },
                                        ]}
                                        xAxis={[{ scaleType: 'band', data:['vata','pitta','kapha']}]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                                fill: 'white',
                                                fontWeight: 'bold',
                                            },
                                        }}

                                        width={600}
                                        height={350}
                                    /> */}

                                    <div className='action-box' style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", margin: "1rem" }}>
                                        <div className='myprakriti'>
                                            <p>My Vikriti</p>
                                            <p>{results[vikritiresult]}<TrendingUpIcon style={{ color: "red" }}></TrendingUpIcon></p>
                                        </div>
                                        {
                                            vikritiresult = '' ?
                                                <Button id='takeprakritibtn' onClick={() => {
                                                    navigate('/vikriti')
                                                }}>Take Vikriti</Button> :

                                                <Button id='takeprakritibtn' onClick={() => {
                                                    navigate('/vikriti')
                                                }}>Retake Vikriti</Button>
                                        }
                                    </div>
                                </div> :
                                    <div width={!isDesktop ? 250 : 500}
                                        className='Takevikritiscreen'>
                                        <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Vikriti.png" />
                                        <Button id='takeprakritibtn' onClick={() => {
                                            navigate('/vikriti')
                                        }}>Take Vikriti</Button>
                                    </div>
                                }
                                <div className='chart'>

                                    <PieChart
                                        colors={palette}
                                        width={!isDesktop ? 250 : 500}
                                        series={[
                                            {
                                                arcLabel: (item) => `${item.name} \n${item.value.toFixed(1)}`,
                                                data: [{ value: prakriti_data.vata, name: 'Vata' }, { value: prakriti_data.pitta, name: 'Pitta' }, { value: prakriti_data.kapha, name: 'Kapha' }]
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                                fill: 'white',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                        {...size}
                                    />
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div className='myprakriti'>
                                            <p>My Prakriti</p>
                                            <p>{results[prakritiresult]}</p>
                                        </div>
                                        {
                                            prakriti_data.length === 0 ? <Button id='takeprakritibtn' onClick={() => {
                                                navigate('/Prakriti')
                                            }}>Take Prakriti</Button> : <Button id='takeprakritibtn' onClick={() => {
                                                navigate('/Prakriti')
                                            }}>Retake Prakriti</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {prakriti_data.length != 0 ?
                                <div className='recommendations-system'>
                                    <p className='mb-0'>Recommendations</p>
                                    <div >
                                        <Tabs value={value} onChange={handleChange} centered variant={isMobile ? 'scrollable' : 'standard'}
                                            scrollButtons={isMobile ? 'on' : 'auto'}>

                                            <LinkTab
                                                icon={<img src={'Diet icon.png'} color="#0076BE" className='reIconsPng' />} iconPosition="start" label="Dietary"
                                                id='linktab' onClick={() => {
                                                    navigate('/')
                                                }}

                                            >

                                            </LinkTab>

                                            {typeof interests === 'object' && interests !== null &&
                                                typeof disease === 'object' && disease != null &&
                                                <LinkTab label="Nutraceutical" to='UpcomingAppointments'
                                                    icon={<img src={'Products Icons.png'} color="#0076BE" className='reIconsPng' />} iconPosition="start"
                                                    id='linktab'
                                                    onClick={() => {
                                                        navigate('Nutraceutical')
                                                    }} />}


                                            <LinkTab label="Articles" to='UpcomingAppointments' icon={<img src={'Article icon.png'} color="#0076BE" className='reIconsPng' />} iconPosition="start"
                                                id='linktab'
                                                onClick={() => {
                                                    navigate('Articles')
                                                }} />

                                            <LinkTab label="Yoga" icon={<img src={'Yoga icon.png'} color="#0076BE" className='reIconsPng' />} iconPosition="start"
                                                id='linktab'
                                                onClick={() => {
                                                    navigate('Yoga')
                                                }} />

                                            <LinkTab label="Pranayama" id='linktab'
                                                icon={<img src={'Pranayama Icon.png'} color="#0076BE" className='reIconsPng' />}
                                                iconPosition="start"
                                                onClick={() => {
                                                    navigate('pranayama')
                                                }} />

                                            <LinkTab label="Meditation" id='linktab'
                                                icon={<img src={'Meditation Icon.png'} color="#0076BE" className='reIconsPng' />}
                                                iconPosition="start"
                                                onClick={() => {
                                                    navigate('meditation')
                                                }} />

                                        </Tabs>
                                    </div>
                                    <>
                                        <Outlet></Outlet>
                                    </>
                                </div> : <></>}
                            <div className='disclaimer'>
                                <p className='mb-0'><ReportIcon style={{ color: 'red' }} /> Disclaimer :</p>
                                <span>
                                    The therapeutic suggestions provided here are intended to be general guidelines. Please
                                    Do Not SELF-MEDICATE and instead seek professional help if you have any health problems or concerns.
                                    AIWELL accepts no liablility for any act or omission resulting from the interpretation of the recommendations

                                </span>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='footer-policy2'
                    style={{ padding: '10px', backgroundColor: '#E9F6FF', textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "space-between", position: "fixed", bottom: 0, width: `calc(100% - ${drawerWidth}px)`, zIndex: 1000 }}>
                    <p className='mb-0'>&copy; 2024 Ayurai Pvt Ltd. All Rights Reserved.</p>
                    <p className='mb-0'>
                        <a href="/Policy?Policy=1">Privacy Policy</a> |
                        <a href="/Policy?Policy=4"> Terms and Conditions</a> |
                        <a href="/Policy?Policy=3">Return and Refund Policy</a>
                    </p>
                </div>
            </Box>
        </Box> : <div className='loading'>
            <Lottie animationData={loader} className='loader-animation' />
            <p>Did you Know ?</p>
            <span>Ayurveda has been around as long as the practice of yoga,and is considered to be the 'sister science' to yoga</span>
        </div>
    }</>


}

export default Final
